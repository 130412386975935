module.exports = {
	clean: [
		'Looka there, looka there now',
		'Looka there, looka there',
		"Coming in peace and love, y'all",
		'Salty tears beyond my gaze',
		'L-l-l-l-l-l-l-l-l-l-looka there',
		'Used to say I spoke too country',
		"And the rejection came, said I wasn't country 'nough'",
		"Said I wouldn't saddle up",
		"If that ain't country, tell me what is?",
		'The grandbaby of a moonshine man',
		"This ain't Texas",
		"Ain't no hold 'em",
		'So lay your cards down, down, down, down',
		'So park your Lexus',
		'And throw your keys up',
		"Stick around, 'round, 'round, 'round, 'round",
		"And I'll be damned if I can't slow-dance with you",
		'Come pour some sugar on me, honey, too',
		"It's a real-life boogie and a real-life hoedown",
		"Don't be a bitch, come take it to the floor now, woo",
		'We heading to the dive bar we always thought was nice',
		"I ain't in no gang, but I got shooters and I bang-bang",
		"At the snap of my fingers, I'm Thanos, da-na, da-na",
		"And I'm still on your head, cornrows, da-na, da-na",
		"Cunty, country, petty, petty, petty",
		"All the same to me, Plain Jane, spaghetti",
		"'Cause I'm a clever girl, we snappin'",
		'B-E-Y-I-N-C-E, ya',
		"We swimmin' (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)",
		"We jerkin' (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)",
		"We twerkin' (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)",
		"You wanna touch it, don't you? (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)",
		'Come get this genie in a bottle (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)',
		"Come rub it, won't you? (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)",
		'I pop it (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)',
		'I walk it like I talk it (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)',
		"I got your spurs sparkin' (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)",
		"You call me pretty little thing",
		"And I love to turn him on",
		"Boy, I'll let you be my Levi's jeans",
		"Come here, you sexy little thing",
		"Snap a picture, bring it on",
		"Oh, you wish you were my Levi's jeans",
		"Way it's poppin' out your phone",
		"Love you down to the bone",
		"I just pray that we don't crash, keep my Bible on the dash",
		'We gotta keep the faith',
		"Runnin' through the river",
		"Runnin' through the river with you, dance",
		"Buckin' like a mechanical bull",
		'Look at that horse, look at that horse, Look at that horse',
		'Say the things that I know will offend',
	],
	explicit: [
		"Those petty ones can't fuck with me (Why?)",
		"Fuck it, we shakin' (Ya-ya-ya-ya-ya, ya-ya-ya-ya-ya-ya)",
		"So you can hug that ass all day long",
		'Bounce on that shit, dance',
		'Bounce on that shit, no hands',
		'Wear that shit that I know start a trend',
	],
};
